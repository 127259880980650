.search-circle-wrapper {
	position: relative;
  }
  .circle_search {
	  display: flex;
  }
  .icon {
    position: absolute;
    color: #fff;
    top: -1px;
    left: 13px;
    font-size: 10px
  }
  
  .circle {
	display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding: 2.5px;
    background-clip: content-box;
    animation: spin 10s linear infinite;
  }
  
  .search-circle-wrapper:active .circle {
	animation: spin 2s linear infinite;
  }
  
  .success {
	background-color: #d39147;
    border: 2.5px dashed #f5995b;
  }
  
  .error {
	background-color: #CA0B00;
	border: 2.5px dashed #CA0B00;
  }
  
  .warning {
	background-color: #F0D500;
	border: 2.5px dashed #F0D500;
  }
  
  @keyframes spin { 
	100% { 
	  transform: rotateZ(360deg);
	}
  }
  
  .page-wrapper {
	height: 100vh;
	background-color: #eee;
	display: flex;
	align-items: center;
	justify-content: center;
  }