/* Body Styles */

  
.author {
    position: fixed;
    bottom: 15px;
    right: 15px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #999;
  }
  
  .author a {
    color: #777;
    text-decoration: none;
  }
  
  .author a:hover {
    color: blue;

  }
  /* Navigation Styles */
  nav { position: relative;  }
 
  ul.main-nav { 
    list-style-type: none; 
    padding: 0px;
    font-size: 0px;
    margin: 0 auto;
    text-align: right;
    display: rtl;
  }
  
  ul.main-nav > li { 
    display: inline-block;
  }
  
  ul.main-nav > li > a { 
    display: block; 
  }
  
  ul.main-nav > li:hover > a { color: #f9f9f9; font-weight: 400; }
  
  ul.main-nav > li ul.sub-menu-lists {
    margin: 0px;
    padding: 0px;
    list-style-type : none;
    display:block;
  }
  
  ul.main-nav > li ul.sub-menu-lists > li {
    padding: 2px 0;
  }
  
  ul.main-nav > li ul.sub-menu-lists > li > a {
    font-size: 14px;
  }
  
  .ic {
    position: fixed; 
    cursor: pointer;
    display: inline-block;
    right: 25px;
    width: 32px;
    height: 24px;
    text-align: center;
    top:0px;
    outline: none;
  }
  
  .ic.close { 
    opacity: 0; 
    font-size: 0px; 
    font-weight: 300; 
    color: #fff;
    top:8px;
    height:40px;
    display: block;
    outline: none;
  }
  
  /* Menu Icons for Devices*/
  .ic.menu { top:25px; z-index : 20; }
  
  .ic.menu .line { 
    height: 4px; 
    width: 100%; 
    display: block; 
    margin-bottom: 6px; 
  }
  .ic.menu .line-last-child { margin-bottom: 0px;  }
  
  .sub-menu-head { margin: 10px 0; }
  .banners-area { margin-top: 20px; padding-top: 15px; }
  
  .banners-area img {width: 150px;}
  
  
  @media only screen and (max-width:768px) {
  
  
    nav { background-color: transparent; }
    
    ul.main-nav > * { 
      -webkit-transition-property: opacity;
      -moz-transition-property: opacity;
      -o-transition-property: opacity;
      transition-property: opacity;
         -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      opacity: 1;
    }
    .navbar-nav li {
      padding: 0px;
    }
    ul.main-nav > li > a:after {display: none;}
    ul.main-nav > li:first-child { border-radius: 0px; }
    ul.main-nav > li {
      display: block;
      padding: 1px 15px;
    }
    
    ul.main-nav > li > a { font-weight: 600; color: #fff !important; }
    
    ul.main-nav > li ul.sub-menu-lists > li a { color: #eee; font-size: 14px; }
    .sub-menu-head { font-size: 16px;}
    ul.main-nav > li:hover > a {color: #fff; text-decoration: none; font-weight: 600;}

    
    .sub-menu-block { padding: 0 30px; }
    .sub-menu-block {display: none;}
    .banners-area { padding-bottom: 0px;  }
    .banners-area div { margin-bottom: 15px;  }
    .banners-area { border-top: 1px solid #444; }
  }
  
  @media only screen and (min-width:769px) {
    /* Main Menu for Desktop Devices  */
    ul.main-nav { display: block; position: relative; }
    .sub-menu-block { padding: 20px; }
    
    /* Sub Menu */
    ul.main-nav > li > div.sub-menu-block { 
    visibility: hidden;
    background-color: #f9f9f9;
    position: absolute;
    margin-top: 13px;
    width: 100%;
    color: #333;
    left: 0;
    box-sizing: border-box;
    z-index : 3;
    font-size: 16px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    opacity: 0;
      
    /*CSS animation applied for sub menu : Slide from Top */
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    
    }
    
    ul.main-nav > li:hover > div.sub-menu-block{ 
      background-color: #f9f9f9; 
      visibility: visible;
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    
    ul.main-nav > li > div.sub-menu-block > * {
      -webkit-transition-property: opacity;
      -moz-transition-property: opacity;
      -o-transition-property: opacity;
      transition-property: opacity;
         -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      opacity: 0;
    }
    
    ul.main-nav > li:hover > div.sub-menu-block > * {
      opacity: 1;
    }
    
    .sub-menu-head { font-size: 20px;}
    
    /* List Separator: Outer Border */
    
    /* List Separator: Inner Border */
    ul.main-nav > li > a:after {
      content: '';
      width: 1px;
      height: 62px;
      position: absolute;
      right:0px;
      top: 0px;
      z-index : 2;
    }
    
    /* Drop Down/Up Arrow for Mega Menu */
    .mega-menu {
      padding-left: 15px;
    }
    ul.main-nav > li > a.mega-menu > span { display: block; vertical-align: middle; 
    color: #fff; }
    ul.main-nav > li > a.mega-menu > span:after {
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #fff;
      content: '';
      background-color: transparent;
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
    }
  
    ul.main-nav > li:hover > a.mega-menu span:after{
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 0px solid transparent;
      border-bottom: 5px solid #fff;
    }
    .banners-area { border-top: 1px solid #ccc; }
  }